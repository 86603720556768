import { useEffect, useState } from "react";
import { getPropertyGetter } from "../utility/individual";

export const useValidator = (individual, validator) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let errorObj = {};
    const get = getPropertyGetter(individual);
    validator(get, (key, value) => {
      errorObj[key] = value;
    });

    if (JSON.stringify(errors) !== JSON.stringify(errorObj)) {
      setErrors(errorObj);
    }
  }, [individual, errors, validator]);

  return [!Object.keys(errors).length, errors];
};
