import { useValidator } from "../useValidator";

export const usePatientValidator = (patientIndividual) =>
  useValidator(patientIndividual, (get, error) => {
    if (!get("firstname")) {
      error("firstName", "First name cannot be empty");
    }
    if (!get("lastname")) {
      error("lastName", "Last name cannot be empty");
    }

    if (!get("birthdate")){
      error("birthdate", "Birthdate cannot be empty");
    }
      if (
        !patientIndividual.communities ||
        !patientIndividual.communities.length
      ) {
        error("communities", "Must add at least 1 community");
      }

    if (!get("phone1")) {
      error("phone1", "Phone Number cannot be empty");
    }
  });
