import React, { useState } from "react";
import { Form, FormGroup, Col, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Refferal } from "../components/Refferal";
import { Patient } from "../components/Patient";
import { Physician } from "../components/Physician";
import { EmergencyContact } from "../components/EmergencyContact";

import { usePhysicianValidator } from "../hooks/error/usePhysicianValidator";

import { createIndividuals, saveFile } from "../api";
import { usePatientValidator } from "../hooks/error/usePatientValidator";
import { useRefferalValidator } from "../hooks/error/useReferralValidator";
import { useEmergencyContactValidator } from "../hooks/error/useEmergencyContactValidator";
import { checkIfIndividualHasEmptyProperties } from "../utility/individual";

const getIndividualOfType = (type) => ({
  properties: [{ property: "type", value: type }],
});

export const Knute = () => {
  const history = useHistory();
  const [referralIndividual, setRefferalIndividual] = useState({
    ...getIndividualOfType("Referral"),
    relationship: "Referral Creator",
  });
  const [patientIndividual, setPatientIndividual] = useState(
    getIndividualOfType("Patient")
  );
  const [emergencyContactIndividual, setEmergencyContactIndividual] = useState(
    getIndividualOfType("Contact")
  );

  const [physicianIndividual, setPhysicianIndividual] = useState({
    ...getIndividualOfType("Contact"),
    relationship: "Primary Physician",
  });

  const [file, setFile] = useState();
  const [isValidating, setIsValidating] = useState(false);

  const [isRefferalValid, refferalErrors] =
    useRefferalValidator(referralIndividual);

  const [isPhysicianValid, physicianErrors] =
    usePhysicianValidator(physicianIndividual);

  const [isPatientValid, patientErrors] =
    usePatientValidator(patientIndividual);

  const [isContactValid, contactErrors] = useEmergencyContactValidator(
    emergencyContactIndividual
  );

  const onSave = async (e) => {
    if (
      !isRefferalValid ||
      !isPatientValid ||
      !isContactValid ||
      !isPhysicianValid
    ) {
      setIsValidating(true);
      return;
    }
    e.currentTarget.disabled = true;
    const communities = patientIndividual.communities;
    const individualsToCreate = [
      {
        ...referralIndividual,
        communities,
      },
      patientIndividual,
    ];

    if (
      !checkIfIndividualHasEmptyProperties(physicianIndividual, [
        "firstname",
        "lastname",
      ])
    ) {
      individualsToCreate.push({
        ...physicianIndividual,
        communities,
      });
    }

    if (
      !checkIfIndividualHasEmptyProperties(emergencyContactIndividual, [
        "firstname",
        "lastname",
      ])
    ) {
      individualsToCreate.push({
        ...emergencyContactIndividual,
        communities,
      });
    }
    const createIndividualResponse = await createIndividuals(
      JSON.stringify({
        Archive: true,
        individuals: individualsToCreate,
      })
    );
    if (file) {
      const individualId = createIndividualResponse.data.Individuals.find(
        (x) => x.type === 1
      ).id;
      //const data = new FormData();
      //data.append("file", file);
      await saveFile(individualId, file.name, file);
    }
    history.push("/completed", {
      communities: communities.map((x) => x.Name),
    });
  };

  return (
    <div>
      <Form className="form">
        <h4>Referral By</h4>
        <Refferal
          updateFunction={setRefferalIndividual}
          individual={referralIndividual}
          errors={refferalErrors}
          isValidating={isValidating}
        />
        <br />
        <h4>Patient Information</h4>
        <Patient
          updateFunction={setPatientIndividual}
          individual={patientIndividual}
          setFile={setFile}
          errors={patientErrors}
          isValidating={isValidating}
        />
        <br />
        <h4>Primary Care Physician</h4>
        <Physician
          updateFunction={setPhysicianIndividual}
          individual={physicianIndividual}
          errors={physicianErrors}
          isValidating={isValidating}
        />
        <br />
        <h4>Alternate Contact</h4>
        <EmergencyContact
          updateFunction={setEmergencyContactIndividual}
          individual={emergencyContactIndividual}
          errors={contactErrors}
          isValidating={isValidating}
        />

        <FormGroup check row>
          <Col sm={{ size: 2, offset: 10 }}>
            <Button className="btn btn-primary" onClick={onSave}>
              Send Referral
            </Button>
          </Col>
        </FormGroup>
        <br />
      </Form>
    </div>
  );
};
