import React from "react";
import { FormGroup, Label, Col, Dropdown, Input } from "reactstrap";

import { getPropertyUpdater, getPropertyGetter } from "../utility/individual";
import { ValidatedInput } from "./ValidatedInput";

export const EmergencyContact = ({
  updateFunction,
  individual,
  errors,
  isValidating,
}) => {
  const updateProperty = getPropertyUpdater(updateFunction, individual);
  const getProperty = getPropertyGetter(individual);

  return (
    <>
      <FormGroup row>
        <Label for="firstName" sm={2}>
          First Name
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="firstName"
            id="contactFirstName"
            placeholder=""
            value={getProperty("firstname")}
            onChange={(e) => updateProperty("firstname", e.target.value)}
            errors={errors}
            errorkey="firstName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="lastName" sm={2}>
          Last Name
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="lastName"
            id="emergencyLastName"
            placeholder=""
            value={getProperty("lastname")}
            onChange={(e) => updateProperty("lastname", e.target.value)}
            errors={errors}
            errorkey="lastName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="homePhone" sm={2}>
          Phone
        </Label>
        <Col sm={10}>
          <Input
            type="phone"
            name="homePhone"
            id="contactPhone"
            placeholder=""
            value={getProperty("phone1")}
            onChange={(e) => updateProperty("phone1", e.target.value)}
            errors={errors}
            errorkey="phone1"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="ecRelationship" sm={2}>
          Relationship 
        </Label>
        <Col sm={10}>
          <Dropdown>
            <Input
              type="select"
              name="select"
              id="ecRelationship"
              value={individual.relationship}
              onChange={(e) =>
                updateFunction({ ...individual, relationship: e.target.value })
              }
              errors={errors}
              errorkey="relationship"
              isvalidating={isValidating}
            >
              <option>--- Select Relationship ---</option>
              <option>Agency</option>
              <option>Aunt</option>
              <option>Brother</option>
              <option>Brother-in-law</option>
              <option>Caregiver</option>
              <option>Clients</option>
              <option>Contact</option>
              <option>Cousin</option>
              <option>Daughter</option>
              <option>Daughter-in-law</option>
              <option>Extended Family</option>
              <option>Family</option>
              <option>Father</option>
              <option>Grandchild</option>
              <option>Granddaughter</option>
              <option>Grandparents</option>
              <option>Grandson</option>
              <option>Legal Guardian</option>
              <option>Mother</option>
              <option>Nephew</option>
              <option>Niece</option>
              <option>Partner</option>
              <option>Pastor</option>
              <option>Physician</option>
              <option>Power of Attorney</option>
              <option>Referrer</option>
              <option>Self</option>
              <option>Sister</option>
              <option>Son</option>
              <option>Spouse</option>
              <option>Uncle</option>
              <option>Unkown</option>
            </Input>
          </Dropdown>
        </Col>
      </FormGroup>
    </>
  );
};
