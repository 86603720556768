import React from "react";
import { FormGroup, Input, Label, Col } from "reactstrap";

import { ValidatedInput } from "./ValidatedInput";
import { getPropertyUpdater, getPropertyGetter } from "../utility/individual";

export const Physician = ({
  updateFunction,
  individual,
  errors,
  isValidating,
}) => {
  const updateProperty = getPropertyUpdater(updateFunction, individual);
  const getProperty = getPropertyGetter(individual);

  return (
    <>
      <FormGroup row>
        <Label for="physicianFirstName" sm={2}>
          PCP First Name 
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="physicianFirstName"
            id="physicianFirstName"
            value={getProperty("firstname")}
            onChange={(e) => updateProperty("firstname", e.target.value)}
            errors={errors}
            errorkey="firstName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="referralPhysicianLastName" sm={2}>
          PCP Last Name 
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="referralPhysicianLastName"
            id="referralPhysicianLastName"
            value={getProperty("lastname")}
            onChange={(e) => updateProperty("lastname", e.target.value)}
            errors={errors}
            errorkey="lastName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="referralPracticeName" sm={2}>
          Practice Name
        </Label>
        <Col sm={10}>
          <Input
            type="input"
            name="referralPracticeName"
            id="referralPracticeName"
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="physicianPhoneNumber" sm={2}>
          PCP Phone Number
        </Label>
        <Col sm={10}>
          <Input
            type="phone"
            name="physicianPhoneNumber"
            id="physicianPhoneNumber"
            value={getProperty("phone1")}
            onChange={(e) => updateProperty("phone1", e.target.value)}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="physicianFaxNumber" sm={2}>
          PCP Fax Number
        </Label>
        <Col sm={10}>
          <Input
            type="phone"
            name="physicianFaxNumber"
            id="physicianFaxNumber"
          />
        </Col>
      </FormGroup>
    </>
  );
};
