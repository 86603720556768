import { useValidator } from "../useValidator";

export const useRefferalValidator = (referralIndividual) =>
  useValidator(referralIndividual, (get, error) => {
    if (!get("firstname")) {
      error("firstName", "First name cannot be empty");
    }
    if (!get("lastname")) {
      error("lastName", "Last name cannot be empty");
    }
    if (!get("email")) {
      error("email", "Email cannot be empty");
    }
    /*if (!get("organizationId")) {
      error("organization", "Facility cannot be empty");
    }*/
    if (!referralIndividual.notes?.[0]?.Message) {
      error("facility", "Refferal facility cannot be empty");
    }
    if (!get("phone1")) {
      error("phone1", "Phone Number cannot be empty");
    }
  });
