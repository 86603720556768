import { useValidator } from "../useValidator";

export const usePhysicianValidator = (physicianIndividual) =>
  useValidator(physicianIndividual, (get, error) => {
    if (get("firstname") && !get("lastname")) {
      error("lastName", "Must add first and last name");
    }
    if (get("lastname") && !get("firstname")) {
      error("firstName", "Must add first and last name");
    }
  });
