import React, { Component } from 'react';
import { Button, Input, InputGroup, Badge, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';


export class Locations extends Component {

  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = { 
                    forecasts: [], 
                    loading: true, 
                    search: "",
                    selected: []
                 };
  }

  handleSearch(e) {
    if (e.target.value != ""){
      var filtered = this.state.forecasts.filter((i => !i.Community.includes(e.target.value)));
      this.setState({
        forecasts: filtered
      });
    }
  }


  selectLocation = (e) => {
    var nameUnique = e.target.attributes.getNamedItem('data-name-unique').value;
    var community = e.target.attributes.getNamedItem('data-community').value;
    var isRemove = e.target.attributes.getNamedItem('data-remove').value;

    var x = [];
    for (var i = 0; i < this.state.forecasts.length; i++){
        
        if (this.state.forecasts[i].NameUnique === nameUnique){
  
          if (isRemove === "yes"){
            this.state.forecasts[i].Selected = false;
          } 

          if (isRemove === "no"){
            this.state.forecasts[i].Selected = true;
          }
          
          x.push(this.state.forecasts[i]);
        }
        else {
          x.push(this.state.forecasts[i]);
        }
    }
    this.setState({ forecasts: x });
  }

  componentDidMount() {
    this.populateData();
  }

  renderEmptyState(){
    return (
      <div>
          <InputGroup size="lg">
            <Input name="searchInput" id="searchInput" placeholder="Search for locations to send referral" onChange={this.handleSearch} />
          </InputGroup>
          <h4>No locations found</h4>
      </div>
    );
  }

   renderForecastsTable(forecasts) {
    return (
      <div>
        <InputGroup size="lg">
          <Input name="searchInput" id="searchInput" placeholder="Search for location" onChange={this.handleSearch} />
        </InputGroup>
        <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Name</th>
              <th>City, State, Zip</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {forecasts.map(forecast =>
              <tr key={forecast.NameUnique}>
                <td>{forecast.Company} - {forecast.Community} - {forecast.CareLevel}</td>
                <td>
                    <span>{forecast.City}, </span>
                    <span>{forecast.State}</span> 
                    <span> {forecast.PostalCode}</span> 
                </td>
                <td>

                {!forecast.Selected && 
                  <div>
                    <Button 
                      onClick={this.selectLocation} 
                      data-name-unique={forecast.NameUnique} 
                      data-community={forecast.Community} 
                      data-remove="no"
                      className="btn btn-primary btn-sm">Select</Button>
                  </div>
                }

                {forecast.Selected && 
                  <div>
                    <Button 
                            onClick={this.selectLocation} 
                            data-name-unique={forecast.NameUnique} 
                            data-community={forecast.Community} 
                            data-remove="yes"
                            className="btn btn-danger btn-sm">Remove</Button>
                        </div>
                      } 
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="fixed-bottom" style={{ paddingTop: "16px", backgroundColor: "#F8F8F8", minHeight: "100px", borderTop: "1px solid #ccc" }}>
          <div className="row">
              <div className="col-lg-10 pl-10">
                {this.state.selected.map(l =>
                  <div key={l.NameUnique}>
                    <Badge color="primary">{l.Community}</Badge>
                  </div>
              )}
              </div>
              <div className="col-lg-2">
                <NavLink to="/referral" tag={Link} className="text-dark"> 
                <Button className="btn btn-primary btn-lg">Next</Button>
                </NavLink>
              </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : (this.state.forecasts.length == 0 ?  this.renderEmptyState() : 
        this.renderForecastsTable(this.state.forecasts));


    return (
      <div>
        <h1 id="tabelLabel">Please Choose Locations</h1>
        {contents}
      </div>
    );
  }


  async populateData() {
    const response = await fetch('/locations.json');
    const data = await response.json();

    const data2  = [...new Map(data.map(item =>
      [item["NameUnique"], item])).values()];


      data2.sort( function( a, b ) {
        a = a.Company.toLowerCase();
        b = b.Company.toLowerCase();
    
        return a < b ? -1 : a > b ? 1 : 0;
     });
 
    this.setState({ forecasts: data2, loading: false });
  }

}
