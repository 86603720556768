import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';

import { Referral } from './pages/Referral';
import { Locations } from './pages/Locations';
import { Completed } from './pages/Completed';
import { Knute } from './pages/Knute';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/referral' component={Referral} />
        <Route path='/locations' component={Locations} />
        <Route path='/completed' component={Completed} />
        <Route path='/knute' component={Knute} />
      </Layout>
    );
  }
}
