import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label, Col } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";

import { getOrganizations } from "../../api";
import { ValidatedInput } from "../ValidatedInput";
import { dropDownError } from "../../styles";

import {
  getPropertyUpdater,
  getPropertyGetter,
} from "../../utility/individual";

export const Refferal = ({
  updateFunction,
  individual,
  errors,
  isValidating,
}) => {
  const [organizations, setOrganizations] = useState();
  useEffect(() => {
    const retrieveOrganizations = async () => {
      const orgs = await getOrganizations();
      setOrganizations(orgs.data);
    };

    retrieveOrganizations();
  }, []);

  const updateProperty = getPropertyUpdater(updateFunction, individual);
  const getProperty = getPropertyGetter(individual);

  const updateNotes = (note) => {
    updateFunction({
      ...individual,
      notes: [{ Message: note }],
    });
  };

  return (
    <>
      <FormGroup row>
        <Label for="firstName" sm={2}>
          First Name *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="firstName"
            id="referralFirstName"
            placeholder=""
            value={getProperty("firstname")}
            onChange={(e) => updateProperty("firstname", e.target.value)}
            errors={errors}
            errorkey="firstName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="lastName" sm={2}>
          Last Name *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="lastName"
            id="refferalLastName"
            placeholder=""
            value={getProperty("lastname")}
            onChange={(e) => updateProperty("lastname", e.target.value)}
            errors={errors}
            errorkey="lastName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="email" sm={2}>
          Email *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="email"
            name="email"
            id="exampleEmail"
            placeholder="example@example.com"
            value={getProperty("email")}
            onChange={(e) => updateProperty("email", e.target.value)}
            errors={errors}
            errorkey="email"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="referralFacility" sm={2}>
          Facility *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="referralFacility"
            name="referralFacility"
            id="referralFacility"
            value={individual.notes?.[0]?.Message || ""}
            onChange={(e) => updateNotes(e.target.value)}
            errors={errors}
            errorkey="facility"
            isvalidating={isValidating}
          />
        </Col>
        {/*
        <Col sm={10}>
          {organizations ? (
            <Multiselect
              selectionLimit="1"
              avoidHighlightFirstOption={true}
              placeholder="--Select Facility--"
              options={organizations.map((x) => ({
                id: x.Id,
                name: x.Name,
              }))}
              selectedValues={organizations.find(
                (x) => x.Id.toString() === getProperty("organizationId")
              )}
              closeIcon="close"
              displayValue="name"
              onSelect={(e) => {
                updateProperty("organizationId", e[0].id);
              }}
              onRemove={(e) => {
                updateProperty("organizationId", null);
              }}
              style={
                errors.hasOwnProperty("organization") && isValidating
                  ? dropDownError
                  : ""
              }
            />
          ) : (
            <span>Loading...</span>
          )}
          {errors.hasOwnProperty("organization") && isValidating ? (
            <small class="text-danger">{errors["organization"]}</small>
          ) : (
            ""
          )}
        </Col>
          */}
      </FormGroup>

      <FormGroup row>
        <Label for="referralPhoneNumber" sm={2}>
          Phone Number *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="phone"
            name="referralPhoneNumber"
            id="referralPhoneNumber"
            value={getProperty("phone1")}
            onChange={(e) => updateProperty("phone1", e.target.value)}
            errors={errors}
            errorkey="phone1"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="referralFaxNumber" sm={2}>
          Fax Number
        </Label>
        <Col sm={10}>
          <Input
            type="phone"
            name="referralFaxNumber"
            id="referralFaxNumber"
            value={getProperty("fax")}
            onChange={(e) => updateProperty("fax", e.target.value)}
          />
        </Col>
      </FormGroup>
    </>
  );
};
