import React, { Component } from "react";
import { Button, NavLink, Alert } from "reactstrap";
import { Link } from "react-router-dom";

export class Completed extends Component {
  constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
  }

  render() {
    const { location } = this.props;
    const communities = location.state.communities;

    return (
      <div>
        <Alert color="success">
          <h4 className="alert-heading">Thank you!</h4>
          <p>
            Your referral has been sent to{" "}
            <CommunitiesToText communities={communities} />.
          </p>
          <hr />
          <p className="mb-0">
            <p>
            Send another Referral using the button below.
            </p>
            <NavLink to="/knute" tag={Link}>
              <Button className="btn btn-primary ">New Referral</Button>
            </NavLink>
          </p>
        </Alert>
      </div>
    );
  }
}

const CommunitiesToText = ({ communities }) => {
  if (communities.length === 1) return <b>{communities[0]}</b>;
  const firsts = communities.slice(0, communities.length - 1);
  const last = communities[communities.length - 1];

  return (
    <>
      {firsts.map((x) => (
        <b>{x}, </b>
      ))}
      <span>and</span> <b>{last}</b>
    </>
  );
};
