import React from "react";
import { Input } from "reactstrap";

export const ValidatedInput = ({ errors, errorkey, isvalidating, ...rest }) => {
  const isInvalid = errors.hasOwnProperty(errorkey) && isvalidating;
  const className = (isInvalid ? "is-invalid" : "") + " " + rest.className;

  return (
    <>
      <Input {...rest} className={className} />
      {isInvalid ? <small class="text-danger">{errors[errorkey]}</small> : ""}
    </>
  );
};
