import axios from "axios";

const subscriptionKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
const portalId = process.env.REACT_APP_PORTAL_ID;
const apiUrl = process.env.REACT_APP_API_URL;

const getApiHeader = (contentType = "application/json") => ({
  "Content-Type": contentType,
  "Ocp-Apim-Subscription-Key": subscriptionKey,
});

export const createIndividuals = async (data) => {
  const response = await axios.post(
    `${apiUrl}Individual?PortalId=${portalId}`,
    data,
    {
      headers: getApiHeader(),
    }
  );
  return response;
};

export const getCommunities = async () => {
  const response = await axios.get(`${apiUrl}Community?PortalId=${portalId}`, {
    headers: getApiHeader(),
  });
  return response;
};

export const getOrganizations = async () => {
  const response = await axios.get(`${apiUrl}organizations?PortalId=${portalId}`, {
    headers: getApiHeader(),
  });
  return response;
};

export const saveFile = async (individualId, fileName, fileContents) => {
  const headers = getApiHeader("application/pdf");
  const response = await axios.post(
    `${apiUrl}document/?PortalId=${portalId}&IndividualId=${individualId}&FileName=${fileName}`,
    fileContents,
    {
      headers,
    }
  );
  return response;
};
