import { useValidator } from "../useValidator";

export const useEmergencyContactValidator = (emergencyContactIndividual) =>
  useValidator(emergencyContactIndividual, (get, error) => {
    if (get("firstname") && !get("lastname")) {
      error("lastName", "Must add first and last name");
    }
    if (get("lastname") && !get("firstname")) {
      error("firstName", "Must add first and last name");
    }
  });
