export const getPropertyUpdater =
  (updateFunction, individual) => (name, value) => {
    updateFunction({
      ...individual,
      properties: [
        ...(individual.properties || []).filter((x) => x.property !== name),
        { property: name, value },
      ],
    });
  };

export const getPropertyGetter = (individual) => (name) => {
  if (!individual || !individual.properties) return "";
  return individual.properties.find((x) => x.property === name)?.value || "";
};

export const checkIfIndividualHasEmptyProperties = (
  individual,
  listOfPropertiesThatCannoyBeEmpty
) => {
  const get = getPropertyGetter(individual);
  return listOfPropertiesThatCannoyBeEmpty.some((x) => !get(x));
};
