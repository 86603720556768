import React, { Component } from 'react';
import { Button,Form,FormGroup,Input,Label,Col,FormText,Alert,Dropdown,NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Referral extends Component {

  render () {
    return (
      <div>
       
          <div>
              <Alert color="info">
                <h4 className="alert-heading">Hello!</h4>
                <p>
                  Please enter your Referral information to be sent to <b>The Rosewood Community</b> and <b>Windcrest Nursing and Rehabilitation </b> 
                </p>
                <hr />
                <p className="mb-0">
                  Include Copy of Demographics, Physical, Medication List and Copy of Insurance Cards (if applicable)

                </p>
              </Alert>
            </div>

          <Form className="form"> 
          <h4>Patient Information</h4>
          <FormGroup row>
            <Label for="firstName" sm={2}>First Name</Label>
            <Col sm={10}>
              <Input
                type="input"
                name="firstName"
                id="firstName"
                placeholder=""
              />
            </Col>
            </FormGroup>
            <FormGroup row>
            <Label for="lastName" sm={2}>Last Name</Label>
            <Col sm={10}>
              <Input
                type="input"
                name="lastName"
                id="refferalLastName"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="email" sm={2}>Email</Label>
              <Col sm={10}>
              <Input
                type="email"
                name="email"
                id="exampleEmail"
                placeholder="example@example.com"
              />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="homePhone" sm={2}>Home Phone</Label>
              <Col sm={10}>
              <Input
                type="phone"
                name="homePhone"
                id="referralPhone"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="mobilePhone" sm={2}>Mobile Phone</Label>
              <Col sm={10}>
              <Input
                type="phone"
                name="mobilePhone"
                id="mobilePhone"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="mobilePhone" sm={2}>Address</Label>
              <Col sm={10}>
              <Input
                type="phone"
                name="mobilePhone"
                id="mobilePhone"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="mobilePhone" sm={2}>Gender</Label>
              <Col sm={10}>
              <Dropdown>
                <Input type="select" name="select" id="exampleSelect">
                <option>--- Select Gender ---</option>
                  <option>Male</option>
                  <option>Female</option>
                </Input>
              </Dropdown>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="notes" sm={2}>Notes</Label>
              <Col sm={10}>
              <Input
                type="textarea"
                name="Notes"
                id="Notes"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleFile" sm={2}>Attachments</Label>
              <Col sm={10}>
                <Input type="file" name="file" id="exampleFile" />
                <FormText color="muted">
                  Please upload relevant files and records related to the Referral that you would like sent
                </FormText>
              </Col>
            </FormGroup>
            <h4>Emergency Contact</h4>
            <FormGroup row>
            <Label for="firstName" sm={2}>First Name</Label>
            <Col sm={10}>
              <Input
                type="input"
                name="firstName"
                id="firstName"
                placeholder=""
              />
            </Col>
            </FormGroup>
            <FormGroup row>
            <Label for="lastName" sm={2}>Last Name</Label>
            <Col sm={10}>
              <Input
                type="input"
                name="lastName"
                id="refferalastName"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="homePhone" sm={2}>Phone</Label>
              <Col sm={10}>
              <Input
                type="phone"
                name="homePhone"
                id="referralPhone"
                placeholder=""
              />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="mobilePhone" sm={2}>Gender</Label>
              <Col sm={10}>
              <Dropdown>
                <Input type="select" name="select" id="exampleSelect">
                  <option>--- Select Relationship ---</option>
                  <option>Agency</option>
                  <option>Aunt</option>
                  <option>Brother</option>
                  <option>Brother-in-law</option>
                  <option>Caregiver</option>
                  <option>Clients</option>
                  <option>Contact</option>
                  <option>Cousin</option>
                  <option>Daughter</option>
                  <option>Daughter-in-law</option>
                  <option>Extended Family</option>
                  <option>Family</option>
                  <option>Father</option>
                  <option>Grandchild</option>
                  <option>Granddaughter</option>
                  <option>Grandparents</option>
                  <option>Grandson</option>
                  <option>Legal Guardian</option>
                  <option>Mother</option>
                  <option>Nephew</option>
                  <option>Niece</option>
                  <option>Partner</option>
                  <option>Pastor</option>
                  <option>Physician</option>
                  <option>Power of Attorney</option>
                  <option>Referrer</option>
                  <option>Self</option>
                  <option>Sister</option>
                  <option>Son</option>
                  <option>Spouse</option>
                  <option>Uncle</option>
                  <option>Unkown</option>
                </Input>
              </Dropdown>
              </Col>
            </FormGroup>
            <FormGroup check row>
              <Col sm={{ size: 2, offset: 10 }}>
              
              <NavLink to="/completed" tag={Link} > 
                <Button className="btn btn-primary ">Send Referral</Button>
              </NavLink>

            </Col>
          </FormGroup>
         
        </Form>
      </div>
    );
  }
}
 