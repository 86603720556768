import React, { useState, useEffect } from "react";
import { FormGroup, FormText, Input, Label, Col, Dropdown } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";

import { StatesDropdown } from "./StatesDropdown";
import { getCommunities } from "../api";
import { getPropertyUpdater, getPropertyGetter } from "../utility/individual";
import { ValidatedInput } from "./ValidatedInput";
import { dropDownError } from "../styles";

export const Patient = ({
  updateFunction,
  individual,
  setFile,
  errors,
  isValidating,
}) => {
  const [communities, setCommunities] = useState();
  const updateProperty = getPropertyUpdater(updateFunction, individual);
  const getProperty = getPropertyGetter(individual);

  useEffect(() => {
    const retrieveCommunities = async () => {
      const communities = await getCommunities();
      setCommunities(communities.data);
    };

    retrieveCommunities();
  }, []);

  const updateCommunities = (selectedCommunities) => {
    console.log("selectedCommunities", selectedCommunities);
    updateFunction({
      ...individual,
      communities: selectedCommunities.map((x) => ({
        NameUnique: x.id,
        Name: x.name,
      })),
    });
  };

  const updateAddress = (name, value) => {
    const address = (individual.addresses && individual.addresses[0]) || {};
    address[name] = value;
    updateFunction({
      ...individual,
      addresses: [address],
    });
  };

  const getAddressProperty = (name) => {
    return (individual.addresses && individual.addresses[0][name]) || "";
  };

  const updateNotes = (note) => {
    updateFunction({
      ...individual,
      notes: [{ Message: note }],
    });
  };

  return (
    <>
      <FormGroup row>
        <Label for="community" sm={2}>
          Send To Facility *
        </Label>
        <Col sm={10}>
          {communities ? (
            <Multiselect
              avoidHighlightFirstOption={true}
              placeholder="--Select Facility--"
              options={communities.map((x) => ({
                id: x.NameUnique,
                name: x.Name,
              }))}
              closeIcon="close"
              displayValue="name"
              onSelect={(e) => {
                updateCommunities(e);
              }}
              onRemove={(e) => {
                updateCommunities(e);
              }}
              style={
                errors.hasOwnProperty("communities") && isValidating
                  ? dropDownError
                  : ""
              }
            />
          ) : (
            <span>Loading...</span>
          )}
          {errors.hasOwnProperty("communities") && isValidating ? (
            <small class="text-danger">{errors["communities"]}</small>
          ) : (
            ""
          )}
          <p class="text-muted small">
            If facility unknown, select Knute Nelson Corporate
          </p>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="firstName" sm={2}>
          First Name *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="firstName"
            id="patientFirstName"
            placeholder=""
            value={getProperty("firstname")}
            onChange={(e) => updateProperty("firstname", e.target.value)}
            errors={errors}
            errorkey="firstName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="lastName" sm={2}>
          Last Name *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="input"
            name="lastName"
            id="patientLastName"
            placeholder=""
            value={getProperty("lastname")}
            onChange={(e) => updateProperty("lastname", e.target.value)}
            errors={errors}
            errorkey="lastName"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="exampleEmailPatient" sm={2}>
          Email
        </Label>
        <Col sm={10}>
          <Input
            type="email"
            name="email"
            id="exampleEmailPatient"
            placeholder="example@example.com"
            value={getProperty("email")}
            onChange={(e) => updateProperty("email", e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="ssn" sm={2}>
          SSN / MRN
        </Label>
        <Col sm={10}>
          <Input
            type="password"
            name="ssn"
            id="ssn"
            placeholder=""
            value={getProperty("ssn")}
            onChange={(e) => updateProperty("ssn", e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="homePhone" sm={2}>
          Primary Phone *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="phone"
            name="homePhone"
            id="patientPhone"
            placeholder=""
            value={getProperty("phone1")}
            onChange={(e) => updateProperty("phone1", e.target.value)}
            errors={errors}
            errorkey="phone1"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="mobilePhone" sm={2}>
          Secondary Phone
        </Label>
        <Col sm={10}>
          <Input
            type="phone"
            name="mobilePhone"
            id="mobilePhone"
            placeholder=""
            value={getProperty("phone2")}
            onChange={(e) => updateProperty("phone2", e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="street" sm={2}>
          Street
        </Label>
        <Col sm={10}>
          <Input
            type="street"
            name="street"
            id="street"
            placeholder=""
            value={getAddressProperty("AddressLine1")}
            onChange={(e) => updateAddress("AddressLine1", e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="city" sm={2}>
          City / State
        </Label>
        <Col sm={5}>
          <Input
            type="city"
            name="city"
            id="city"
            placeholder=""
            value={getAddressProperty("City")}
            onChange={(e) => updateAddress("City", e.target.value)}
          />
        </Col>
        <Col sm={5}>
          <StatesDropdown
            id="state"
            placeholder=""
            value={getAddressProperty("State")}
            onChange={(e) => updateAddress("State", e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="zip" sm={2}>
          Zip Code
        </Label>
        <Col sm={10}>
          <Input
            type="zip"
            name="zip"
            id="zip"
            placeholder=""
            value={getAddressProperty("PostalCode")}
            onChange={(e) => updateAddress("PostalCode", e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="birthDate" sm={2}>
          Date of Birth *
        </Label>
        <Col sm={10}>
          <ValidatedInput
            type="date"
            name="birthDate"
            id="birthDate"
            placeholder=""
            value={getProperty("birthdate")}
            onChange={(e) => updateProperty("birthdate", e.target.value)}
            errors={errors}
            errorkey="birthdate"
            isvalidating={isValidating}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="gender" sm={2}>
          Gender
        </Label>
        <Col sm={10}>
          <Dropdown name="gender">
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={getProperty("gender")}
              onChange={(e) => updateProperty("gender", e.target.value)}
            >
              <option>--- Select Gender ---</option>
              <option>Male</option>
              <option>Female</option>
            </Input>
          </Dropdown>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="notes" sm={2}>
          Notes
        </Label>
        <Col sm={10}>
          <Input
            type="textarea"
            name="Notes"
            id="Notes"
            placeholder="Please include any helpful information including related diagnosis for referral placement."
            value={individual.notes?.[0]?.Message || ""}
            onChange={(e) => updateNotes(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="exampleFile" sm={2}>
          Attachments
        </Label>
        <Col sm={10}>
          <Input
            type="file"
            name="file"
            id="exampleFile"
            onChange={(e) => {
              const file = e.target.files[0]; // accesing file
              setFile(file);
            }}
          />
          <FormText color="muted">
            Please include Med List, Insurance info, 3-4 recent progress notes
            and any other records related to referral
          </FormText>
        </Col>
      </FormGroup>
    </>
  );
};
